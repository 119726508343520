/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import s from './FiltersHeader.scss';
import {X} from '../../../../icons/dist';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import classNames from 'classnames';

export interface IFiltersHeaderProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  combineFiltersAndSort?: boolean;
  closeMobileFiltersModal: Function;
  modalHeaderId?: string;
}

export class FiltersHeaderComp extends React.Component<IFiltersHeaderProps> {
  public render() {
    const {shouldShowMobile, isCategoryPage} = this.props.globals;
    const {combineFiltersAndSort} = this.props;

    return (
      <span className={classNames(s.header, {[s.categoryPage]: isCategoryPage})}>
        <h2
          data-hook="filters-title"
          className={classNames(s.title, {[s.categoryPage]: isCategoryPage})}
          id={this.props.modalHeaderId}>
          {combineFiltersAndSort ? this.props.t('mobileFiltersAndSortingText') : this.props.t('filtersTitleText')}
        </h2>
        {shouldShowMobile && (
          <button
            type="button"
            data-hook="cancel"
            aria-label={this.props.t('filtersAriaLabel')}
            className={s.cancel}
            onClick={() => this.props.closeMobileFiltersModal()}>
            <X />
          </button>
        )}
      </span>
    );
  }
}

export const FiltersHeader = withGlobals(withTranslations()(FiltersHeaderComp));
